<template>
    <d-container fluid>
        <pageTitle :back="true"
                   title="Sorteren"/>
        <d-row>
            <vCard :col="12" header="Foto's - sorteren" :actions="cardActions">
                <div v-if="shiftSort.length > 0">
                    <button class="btn btn-primary" @click="sortPhotos">sorteer</button>
                    <span>Auto's geselecteerd voor sorteren {{ shiftSort.length }}</span>
                </div>
                <br/>
                <div v-if="photos.length > 0">
                    <draggable v-model="photos"
                               draggable=".vehicle-card"
                               class="vehicle-card-container">
                        <div v-for="(image, key) in photos" :key="image.id" class="vehicle-card"
                             v-if="image.filename.endsWith('.JPG') || image.filename.endsWith('.jpeg') || image.filename.endsWith('.jpg')"
                             @click="updater($event, image)">
                            <div class="vehicle-card__image"
                                 :style="{backgroundImage: `url(${getPhoto(image)})`}">
                                <div @click="maximizePhoto(image)"
                                     style="position: absolute; top: 0; filter: invert(100%);">
                                    <i class="material-icons">zoom_in</i>
                                </div>
                                <div v-if="(image.hasOwnProperty('selected') && image.selected === true)"
                                     class="vehicle-card__selected">
                                    <h4>Selected {{ selectedIndex(image) }}.</h4>
                                </div>
                            </div>
                            <div class="vehicle-card__types">
                                <v-radio v-model="image.temporaryType"
                                         @input="(e) => { updatePhotoType(e, image) }"
                                         :name="`type-${image.filename}`"
                                         :options="photoTypes"
                                         :show-errors="false"
                                         :disabled="false"/>
                                <button class="btn btn-danger" style="width: 100%" @click="deletePhoto(key)">
                                    <i class="material-icons">delete</i>
                                </button>
                            </div>
                        </div>
                    </draggable>
                </div>
                <div v-else>
                    <p>Geen foto's gevonden, zijn ze al gemaakt ?</p>
                </div>
            </vCard>
            <vCard :col="4" header="Tips">
                <p>Houdt shift ingedrukt </p>
            </vCard>
        </d-row>
        <maximizePhotoModal :modal="maximizePhotoModalData"
                            @closeHandler="(e) => { maximizePhotoModalData.open = e }"/>
    </d-container>
</template>

<script>
import {ContentLoader} from 'vue-content-loader'
import VInput from "@/components/shared/vInput";
import draggable from 'vuedraggable'
import maximizePhotoModal from './../modals/maximizePhoto'

export default {
    title: 'sort',
    components: {VInput, draggable, ContentLoader, maximizePhotoModal},
    data() {
        return {
            shiftSort: [],
            cardActions: [
                {
                    icon: '<i class="material-icons">save</i>',
                    fn: async () => {
                        await this.saveOrder()
                        this.$router.go(-1)
                    }
                }
            ],
            photos: [],
            photoTypes: [
                {name: 'Normaal', type: 0},
                {name: 'Onderhoud', type: 1},
                {name: 'Schade', type: 2}
            ],
            form: {
                type: {
                    value: {name: 'Normaal', type: 'normal'}
                }
            },
            maximizePhotoModalData: {
                open: false,
                photo: {
                    path: null
                }
            },
        }
    },
    created() {
        this.getPhotos()
    },
    methods: {
        async saveOrder() {
            await this.$store.dispatch('photoStudio.studio/setPhotosOrder', {
                licensePlate: this.$route.params.licensePlate,
                photos: this.photos
            })
            return true
        },
        selectedIndex(image) {
            return this.shiftSort.indexOf(image) + 1
        },
        getType(id) {
            return this.photoTypes.filter((type) => {
                return type.type === id
            })[0]
        },
        updatePhotoType(e, image) {
            this.photos[this.photos.indexOf(image)].type = e.value.type
        },
        getPhoto(photo) {
            const licensePlate = this.$route.params.licensePlate
            if (photo.v2) {
                return photo.filename
            }
            return `${this.$dotenv('PHOTOSTUDIO_BASE_URL')}/upload/details/${licensePlate}/${photo.filename}`
        },
        async getPhotos() {
            let photos = await this.$store.dispatch('photoStudio.studio/getPhotosOrder', {
                licensePlate: this.$route.params.licensePlate
            })
            photos.forEach((photo) => {
                photo.temporaryType = {value: this.getType(photo.type)}
            })

            this.photos = photos
        },
        async deletePhoto(key) {
            try {
                this.photos.splice(key, 1)
            } catch (e) {
                alert('Foto kon niet worden verwijderd')
            }
        },
        updater(e, image) {
            if (e.shiftKey) {
                let index = this.shiftSort.indexOf(image)
                if (index === -1) {
                    this.shiftSort.push(image)
                    image.selected = true
                } else {
                    image.selected = false
                    this.shiftSort.splice(index, 1)
                }
            }
        },
        sortPhotos() {
            this.shiftSort.forEach((image) => {
                let index = this.photos.indexOf(image)
                this.photos.splice(index, 1)
            })
            for (let i = 0; i < this.shiftSort.length; i++) {
                this.photos.splice(i, 0, this.shiftSort[i])
                this.shiftSort[i].selected = false
            }

            // @TODO stupid hack to re-set value
            this.photos.forEach((photo) => {
                const type = photo.type
                photo.temporaryType = {value: {name: 'normaal', type: 0}}
                setTimeout(() => {
                    photo.temporaryType = {value: this.getType(type)}
                }, 1)
            })

            this.shiftSort = []
        },
        maximizePhoto(vehiclePhoto) {
            if(vehiclePhoto.filename.startsWith('https://')){
                this.maximizePhotoModalData.photo.path = vehiclePhoto.filename
            } else {
                this.maximizePhotoModalData.photo.path = `upload/details/${this.$route.params.licensePlate}/${vehiclePhoto.filename}`
            }
            this.maximizePhotoModalData.open = true
        }
    }
}
</script>

<style lang="scss">
.vehicle-card-container {
    display: flex;
    flex-wrap: wrap;
}

.vehicle-card {
    background: white;
    cursor: pointer;
    border: 2px solid gray;
    width: 160px;
    margin-bottom: 20px;
    margin-right: 10px;
    border-radius: 5px;

    &__image {
        position: relative;
        background-size: cover;
        height: 104px;
        width: 156px;
    }

    &__selected {
        background-color: rgba(0, 0, 0, 0.5);
        height: 104px;
        width: 156px;
        display: flex;
        align-items: center;

        h4 {
            margin: 0 auto;
            color: #FFF;
        }
    }

    &__types {
        padding: 10px 20px 20px 20px;
    }
}

</style>
